import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { colours, device } from '../../style/variables'

import routes from '../../utils/routes'
import Logo from '../common/AllowaLogo'

const Container = styled.div`
  @media ${device.desktop} {
    margin: 0 3rem;
  }
`

const LogoWrapper = styled.div`
  cursor: pointer;
  padding: 1.5rem 0;
`

const Divider = styled.div`
  background-color: ${colours.allowaBlue};
  width: 100%;
  margin: 1rem auto;
  height: 1px;

  @media ${device.tablet} {
    display: none;
  }
`

function FooterLogo(): React.ReactElement {
  return (
    <Container>
      <LogoWrapper onClick={() => navigate(routes.home)}>
        <Logo fill={'white'} />
      </LogoWrapper>
      <Divider />
    </Container>
  )
}

export default FooterLogo
