import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { device, colours } from '../../style/variables'

import routes from '../../utils/routes'

const Container = styled.div`
  @media ${device.tablet} {
    margin-top: 0.5rem;
    text-align: right;
  }

  @media ${device.desktop} {
    margin: 1rem 1rem 1rem 0;
  }
`

const Row = styled.div`
  @media ${device.desktop} {
    display: flex;
    flex-direction: row;
    justify-content: end;
  }
`

const StyledLink = styled(Link)`
  color: ${colours.grey};
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
  margin: 1rem 0;
  text-decoration: none;
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
    transition: 0.3s;
  }

  @media ${device.desktop} {
    margin-top: 0;
    margin-right: 1rem;

    &:last-of-type {
      margin-right: 0;
    }
  }
`

function LegalLinks(): React.ReactElement {
  return (
    <Container>
      <Row>
        <StyledLink to={routes.legal_notice} target="_blank">Mentions légales</StyledLink>
        <StyledLink to={routes.privacy_policy} target="_blank">Politique de confidentialité</StyledLink>
        <StyledLink to={routes.cgu} target="_blank">CGU</StyledLink>
        <StyledLink to={routes.cookies} target="_blank">Politique de cookies</StyledLink>
        <StyledLink to={routes.fees} target="_blank">Consulter nos honoraires</StyledLink>
      </Row>
      <Row>
        <StyledLink to={routes.data_policy_sales} target="_blank">
          Politique des données personnelles - Achat
        </StyledLink>
        <StyledLink to={routes.data_policy_lettings} target="_blank">
          Politique des données personnelles - Location
        </StyledLink>
      </Row>
    </Container>
  )
}

export default LegalLinks
