import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { device, colours } from '../../style/variables'

import routes from '../../utils/routes'

const Container = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;

  @media ${device.tablet} {
    display: flex;
    flex-direction: row;
  }

  @media ${device.desktop} {
    margin: 0 3rem;
  }
`

const Title = styled.div`
  color: ${colours.grey};
  cursor: default;
  margin: 2rem 0;
`

const StyledLink = styled(Link)`
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
  text-decoration: none;
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
    transition: 0.3s;
  }

  @media ${device.tablet} {
    margin: 1.6rem 0 0 0;
  }
`

const Column = styled.div`
  &:last-of-type {
    padding-top: 0.1rem;
  }

  @media ${device.tablet} {
    margin-bottom: 2rem;
    margin-right: 4rem;

    &:last-of-type {
      margin-right: 0;
      padding-top: 0;
    }
  }

  @media ${device.desktop} {
    &:first-of-type {
      margin-right: 15rem;
    }
  }
`

function MainLinks(): React.ReactElement {
  return (
    <Container>
      <Column>
        <Title>Services</Title>
        <StyledLink to={routes.vente} target="_blank">Acheter</StyledLink>
        <StyledLink to={routes.location} target="_blank">Louer</StyledLink>
        <StyledLink to={routes.vendre} target="_blank">Vendre</StyledLink>
        <StyledLink to={routes.conseil} target="_blank">Conseil</StyledLink>
        <StyledLink to={'/'} target="_blank">Mandataires (prochainement)</StyledLink>
      </Column>
      <Column>
        <Title>Essentiels</Title>
        <StyledLink to={routes.estimateur} target="_blank">Estimateur de bien immobilier</StyledLink>
        <StyledLink to={routes.dossier_location} target="_blank">Dossier de location</StyledLink>
        <StyledLink to={routes.dossier_acquereur} target="_blank">Dossier acquéreur</StyledLink>
        <StyledLink to={routes.espace_vendeur} target="_blank">Mon espace vendeur</StyledLink>
      </Column>
    </Container>
  )
}

export default MainLinks
