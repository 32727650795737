import React from 'react'
import { navigate } from 'gatsby'
import styled from 'styled-components'
import { device } from '../../style/variables'
import SVG from 'react-inlinesvg'

import routes from '../../utils/routes'
import facebook from '../../assets/icons/facebook.svg'
import instagram from '../../assets/icons/instagram.svg'
import linkedin from '../../assets/icons/linkedin.svg'

const Container = styled.div`
  display: flex;
  flex-direction: row;

  @media ${device.tablet} {
    margin: 1rem 0;
  }

  @media ${device.desktop} {
    margin: 0 3rem;
  }
`

const Link = styled.div`
  color: white;
  cursor: pointer;
  margin: 1.2rem 2rem 0.8rem 0;
`

function SocialLinks(): React.ReactElement {
  return (
    <Container>
      <Link onClick={() => navigate(routes.facebook)}>
        <SVG src={facebook} />
      </Link>
      <Link onClick={() => navigate(routes.instagram)}>
        <SVG src={instagram} />
      </Link>
      <Link onClick={() => navigate(routes.linkedin)}>
        <SVG src={linkedin} />
      </Link>
    </Container>
  )
}

export default SocialLinks
