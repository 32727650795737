import React, { useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import { colours, device } from '../style/variables'

import AllowaLogo from './common/AllowaLogo'
import mobileMenu from '../assets/icons/mobile-menu.svg'
import { IconButton } from '@material-ui/core'
import ButtonCommon from './common/Button'
import chevronDown from '../assets/icons/chevron-down-white.svg'
import Menu from '@material-ui/core/Menu'

type HeaderProps = {
  white?: boolean
  onToggleMenu?: () => void
}

const smoothScroll = keyframes`
  0% { transform: translateY(-100%); }
  100% { transform: translateY(0px); }
`

const DropdownMenu = styled.div`
  align-items: center;
  color: #000000;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  text-decoration: none;
  user-select: none;
`
const OuterContainer = styled.div<{ sticky?: boolean }>`
  width: 100%;
  z-index: 3;
  position: ${props => (props.sticky ? 'sticky' : 'relative')};
  top: 0px;
  background: ${props => (props.sticky ? 'white' : 'inherit')};
  justify-content: center;
  align-items: center;
  display: flex;
  height: 90px;
  &.sticky {
    animation: ${smoothScroll} 500ms;
  }
  @media ${device.mobileTablet} {
    align-items: center;
    background: ${colours.blue};
    height: 85px;
    margin: 0px;
    width: 100%;
  }
`

const Container = styled.div<{ sticky?: boolean }>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: auto;
  padding: 1rem 1rem;
  width: 100%;
  max-width: 1300px;

  ${DropdownMenu} {
    margin-top: ${props => (props.sticky ? '14px' : '')};
  }

  @media ${device.mobileTablet} {
    display: flex;
    justify-content: space-between;
    margin: 0px;
    padding-left: 24px;
    padding-right: 24px;
    padding: 8px;
    width: 100%;
  }
`

const LogoBlock = styled(Link)`
  display: flex;
  justify-content: space-between;
  width: 202px;

  @media ${device.mobileTablet} {
    & > * {
      fill: white;
    }
  }
`

const StyledLink = styled(Link)<{ white?: boolean }>`
  align-items: center;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  text-decoration: none;
  user-select: none;
`

const Links = styled.div`
  display: flex;
  position: relative;

  & > * {
    margin-right: 30px;
  }

  & > *:last-child {
    margin-right: 0px;
  }
`

const DropdownContainer = styled(Menu)`
  & .MuiMenu-list {
    padding: 8px 0;
  }
`

const Icon = styled.img`
  margin: 0 0 0 10px;
  transform: rotate(0deg);
  transition: 300ms all;

  &.anchored {
    transform: rotate(180deg);
    transition: 300ms all;
  }
`

const SubTitle = styled(Link)`
  color: black;
  display: flex;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  padding: 0.5rem 1rem;
  text-decoration: none;

  &:hover {
    background-color: #f0f0f0;
  }
`

function Header(props: HeaderProps): React.ReactElement {
  const [sticky, setSticky] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    document.addEventListener('scroll', trackScroll)

    return () => {
      document.removeEventListener('scroll', trackScroll)
    }
  }, [])

  const trackScroll = () => {
    if (typeof window == 'undefined') {
      return
    } else {
      setSticky(window.scrollY >= 420)
    }
  }

  return (
    <OuterContainer sticky={sticky} className={`${sticky ? 'sticky' : ''}`}>
      <Container id="container" sticky={sticky} className={`${sticky ? 'sticky' : ''}`}>
        <LogoBlock to="/" className="desktop-only">
          <AllowaLogo fill={`${props.white && !sticky ? 'white' : colours.blue}`} />
        </LogoBlock>
        <LogoBlock to="/" className="mobile-and-tablet-only" style={{ marginLeft: 20 }}>
          <AllowaLogo fill={'white'} />
        </LogoBlock>
        <Links className="desktop-only">
          <div>
            <DropdownMenu
              onClick={handleClick}
              style={{ color: props.white && !sticky ? 'white' : colours.blue }}
            >
              En savoir plus <Icon src={chevronDown} className={`${anchorEl ? 'anchored' : ''}`} />
            </DropdownMenu>
            <DropdownContainer
              MenuListProps={{ onClick: handleClose }}
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              style={{ top: 50, left: 14 }}
            >
              <SubTitle to="/etapes-de-vente">Le guide vendeur</SubTitle>
              <SubTitle to="/baremes">Nos tarifs</SubTitle>
            </DropdownContainer>
          </div>
          <StyledLink
            to="https://allowa.com"
            style={{ color: props.white && !sticky ? 'white' : colours.blue }}
          >
            Nos annonces
          </StyledLink>
          <StyledLink
            to="/contact"
            style={{ color: props.white && !sticky ? 'white' : colours.blue }}
          >
            Nous contacter
          </StyledLink>
          {sticky && (
            <ButtonCommon orange label="Estimer mon bien" onClick={() => navigate('/estimation')} />
          )}
        </Links>
        <div className="mobile-and-tablet-only">
          <IconButton onClick={props.onToggleMenu}>
            <img src={mobileMenu} style={{ marginBottom: 0 }} />
          </IconButton>
        </div>
      </Container>
    </OuterContainer>
  )
}

export default Header
