import React from 'react'

const AllowaLogo = ({ fill = '#0E47C1' }) => (
 <svg width="127" height="30" viewBox="0 0 612 106" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_1000_10695)">
<path d="M23.1895 100.367H80.5263C90.4043 100.367 95.3507 88.4237 88.364 81.4569L59.7155 52.7736C58.6863 51.7441 57.4645 50.9274 56.1197 50.3703C54.7749 49.8131 53.3335 49.5263 51.8778 49.5263C50.4221 49.5263 48.9807 49.8131 47.6359 50.3703C46.2911 50.9274 45.0693 51.7441 44.0401 52.7736L15.3518 81.442C8.37007 88.4237 13.3165 100.367 23.1895 100.367Z" fill="#010000"/>
<path d="M94.9476 60.4271C93.7148 60.4287 92.4938 60.1869 91.3546 59.7155C90.2154 59.2442 89.1805 58.5525 88.3093 57.6802L60.3077 29.6786C59.1981 28.5652 57.8797 27.6818 56.428 27.079C54.9763 26.4762 53.4198 26.1659 51.848 26.1659C50.2761 26.1659 48.7197 26.4762 47.268 27.079C45.8163 27.6818 44.4978 28.5652 43.3883 29.6786L15.4265 57.6802C14.5549 58.5523 13.52 59.2441 12.381 59.7161C11.242 60.1881 10.0211 60.4311 8.78811 60.4311C7.55514 60.4311 6.33426 60.1881 5.19522 59.7161C4.05619 59.2441 3.02131 58.5523 2.14973 57.6802C-1.5377 54.0127 -0.0846215 49.5241 3.58291 45.8516L37.4217 12.0128C45.8814 3.51826 57.8494 3.47845 66.334 12.0128L100.173 45.8516C103.84 49.5191 105.263 54.0426 101.596 57.7101C100.721 58.5784 99.6837 59.2654 98.5428 59.7316C97.4019 60.1979 96.1801 60.4342 94.9476 60.4271V60.4271Z" fill="#010000"/>
<path d="M256.558 0H246.809C244.891 0 243.336 1.55512 243.336 3.47345V101.068C243.336 102.987 244.891 104.542 246.809 104.542H256.558C258.476 104.542 260.031 102.987 260.031 101.068V3.47345C260.031 1.55512 258.476 0 256.558 0Z" fill="#010000"/>
<path d="M224.067 31.3507H214.324C213.4 31.3507 212.514 31.7177 211.861 32.3709C211.208 33.0242 210.841 33.9102 210.841 34.8341V40.9698H210.034L207.705 38.2727C206.098 36.3767 203.182 34.4808 198.957 32.6594C194.434 30.7639 189.569 29.8204 184.665 29.8876C173.717 29.8876 164.546 33.3163 157.037 40.1687C149.549 47.0957 145.787 56.3532 145.751 67.9413C145.751 79.6058 149.516 88.865 157.047 95.719C164.556 102.571 173.742 105.995 184.675 105.995C191.458 105.995 197.872 104.099 201.445 102.203C203.188 101.263 204.824 100.138 206.327 98.8491C209.313 96.2963 209.681 95.5697 210.044 94.9825H210.85V101.059C210.85 101.982 211.217 102.868 211.871 103.522C212.524 104.175 213.41 104.542 214.334 104.542H224.077C225.001 104.542 225.887 104.175 226.541 103.522C227.194 102.868 227.561 101.982 227.561 101.059V34.8341C227.561 34.3758 227.47 33.922 227.295 33.4987C227.119 33.0755 226.862 32.691 226.537 32.3674C226.213 32.0438 225.827 31.7874 225.404 31.613C224.98 31.4385 224.526 31.3494 224.067 31.3507V31.3507ZM204.287 84.4975C199.838 88.7273 194.08 90.8373 186.934 90.8373C179.788 90.8373 173.961 88.7273 169.587 84.4975C165.213 80.2676 163.023 74.729 163.023 67.9463C163.023 61.1636 165.213 55.6997 169.587 51.4748C173.961 47.2499 179.719 45.13 186.934 45.13C194.15 45.13 199.838 47.2449 204.287 51.4748C208.736 55.7046 210.85 61.1686 210.85 67.9463C210.85 74.724 208.661 80.2626 204.287 84.4925V84.4975Z" fill="#010000"/>
<path d="M607.814 31.3507H598.066C597.142 31.3507 596.256 31.7177 595.603 32.3709C594.949 33.0242 594.582 33.9102 594.582 34.8341V40.9698H593.781L591.447 38.2727C589.845 36.3767 586.929 34.4807 582.699 32.6594C578.178 30.764 573.314 29.8205 568.412 29.8876C557.464 29.8876 548.293 33.3163 540.784 40.1686C533.274 47.0956 529.482 56.3515 529.482 67.9413C529.482 79.6058 533.274 88.8666 540.784 95.719C548.293 102.571 557.489 105.995 568.422 105.995C575.2 105.995 581.619 104.099 585.192 102.203C586.934 101.262 588.57 100.137 590.074 98.8491C593.06 96.2962 593.428 95.5697 593.791 94.9825H594.592V101.059C594.592 101.982 594.959 102.868 595.613 103.522C596.266 104.175 597.152 104.542 598.076 104.542H607.824C608.748 104.542 609.634 104.175 610.287 103.522C610.941 102.868 611.308 101.982 611.308 101.059V34.8341C611.308 34.3758 611.217 33.922 611.042 33.4987C610.866 33.0754 610.608 32.691 610.284 32.3674C609.959 32.0438 609.574 31.7874 609.15 31.613C608.727 31.4385 608.273 31.3493 607.814 31.3507V31.3507ZM588.034 84.4975C583.585 88.7273 577.827 90.8372 570.681 90.8372C563.535 90.8372 557.708 88.7273 553.334 84.4975C548.96 80.2676 546.77 74.729 546.77 67.9463C546.77 61.1636 548.96 55.6997 553.334 51.4748C557.708 47.2499 563.466 45.13 570.681 45.13C577.897 45.13 583.585 47.2449 588.034 51.4748C592.408 55.6997 594.592 61.1686 594.592 67.9463C594.592 74.724 592.408 80.2626 588.034 84.4925V84.4975Z" fill="#010000"/>
<path d="M315.82 67.9413C315.82 56.2072 319.687 46.9464 327.34 40.0741C334.994 33.3412 344.857 29.8876 357.009 29.8876C369.161 29.8876 379.099 33.3412 386.752 40.094C394.406 46.9464 398.198 56.2072 398.198 67.9613C398.198 79.7153 394.406 89.0309 386.752 95.8285C379.099 102.626 369.186 105.995 357.009 105.995C344.832 105.995 334.994 102.566 327.34 95.7887C319.687 89.011 315.82 79.6804 315.82 67.9413ZM380.92 67.9413C380.92 61.1636 378.736 55.6947 374.361 51.4698C369.987 47.2449 364.155 45.125 357.009 45.125C349.863 45.125 344.031 47.24 339.657 51.4698C335.283 55.6997 333.098 61.1636 333.098 67.9413C333.098 74.7191 335.288 80.2626 339.657 84.4925C344.026 88.7223 349.794 90.8323 357.009 90.8323C364.225 90.8323 369.913 88.7223 374.361 84.4925C378.81 80.2626 380.92 74.724 380.92 67.9413Z" fill="#010000"/>
<path d="M463.447 48.8423L447.369 102.069C447.155 102.786 446.715 103.416 446.116 103.864C445.516 104.312 444.788 104.555 444.04 104.557H426.697C425.942 104.555 425.209 104.308 424.606 103.853C424.004 103.398 423.566 102.759 423.358 102.034L404.518 36.108C404.359 35.5526 404.332 34.968 404.437 34.4001C404.543 33.8323 404.779 33.2967 405.127 32.8355C405.474 32.3744 405.925 32.0003 406.442 31.7426C406.958 31.4849 407.528 31.3507 408.106 31.3506H417.466C418.233 31.3522 418.977 31.6067 419.585 32.0745C420.192 32.5423 420.628 33.1975 420.825 33.9383L435.256 88.4337H435.839L452.131 33.8388C452.345 33.1214 452.784 32.4919 453.384 32.0438C453.983 31.5956 454.712 31.3526 455.46 31.3506H472.25C472.999 31.3526 473.727 31.5956 474.326 32.0438C474.926 32.4919 475.365 33.1214 475.579 33.8388L491.802 88.4287H492.459L506.865 33.9333C507.062 33.1925 507.498 32.5374 508.106 32.0695C508.713 31.6017 509.458 31.3472 510.224 31.3456H519.585C520.163 31.3458 520.733 31.4801 521.251 31.738C521.768 31.996 522.219 32.3705 522.567 32.832C522.915 33.2936 523.152 33.8298 523.258 34.3982C523.364 34.9666 523.336 35.5519 523.177 36.108L504.332 102.014C504.124 102.739 503.686 103.378 503.084 103.833C502.482 104.288 501.748 104.535 500.993 104.537H483.666C482.918 104.534 482.191 104.29 481.592 103.842C480.994 103.394 480.555 102.765 480.342 102.049L464.248 48.8025L463.447 48.8423Z" fill="#010000"/>
<path d="M306.176 88.1351H301.374C299.025 88.1351 296.772 87.2019 295.111 85.5407C293.45 83.8796 292.516 81.6265 292.516 79.2773V3.4834C292.516 2.55955 292.149 1.67353 291.496 1.02027C290.843 0.367 289.957 0 289.033 0H279.299C278.375 0 277.489 0.367 276.836 1.02027C276.183 1.67353 275.816 2.55955 275.816 3.4834V82.905C275.816 88.6435 278.096 94.1469 282.153 98.2046C286.211 102.262 291.714 104.542 297.453 104.542H306.171C307.095 104.542 307.981 104.175 308.634 103.522C309.288 102.868 309.655 101.982 309.655 101.059V91.6036C309.651 90.6832 309.283 89.8017 308.631 89.1518C307.979 88.5019 307.097 88.1364 306.176 88.1351V88.1351Z" fill="#010000"/>
</g>
<defs>
<clipPath id="clip0_1000_10695">
<rect width="611.288" height="106" fill="white"/>
</clipPath>
</defs>
</svg>

)

export default AllowaLogo
