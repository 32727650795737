import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button } from '@material-ui/core'
import { colours } from '../../style/variables'

// Props
// Colours: `orange`, `green`, `blue`, `allowaBlue` or `lightBlue`
// Thickness: default (none) or `thin`
// Width: `fullWidth` to fill the container
// `startIcon` and `endIcon`: can add materialUI icons (https://material-ui.com/components/material-icons/) to the button
export type customButton = {
  label: string
  startIcon?: object
  endIcon?: object
  isDisabled?: boolean
  orange?: boolean
  green?: boolean
  blue?: boolean
  allowaBlue?: boolean
  lightBlue?: boolean
  thin?: boolean
  fullWidth?: boolean
  fontSizeSmall?: boolean
  className?: string
  onClick?: () => void
  active?: boolean
  big?: boolean
}

const useStyles = makeStyles(() => ({
  primaryButton: {
    border: 0,
    borderRadius: 5,
    color: 'white',
    fontFamily: `"Poppins", sans-serif`,
    fontSize: 15,
    fontWeight: 500,
    height: 45,
    padding: '10px 30px',
    textTransform: 'none',
    letterSpacing: 0,
    width: 'fit-content',
  },
  primaryButtonOrange: {
    backgroundColor: `${colours.orange}`,
    '&:hover': {
      backgroundColor: `${colours.orangeDark}`,
    },
  },
  primaryButtonGreen: {
    backgroundColor: `${colours.allowaGreen}`,
    '&:hover': {
      backgroundColor: `${colours.allowaGreenDark}`,
    },
  },
  primaryButtonBlue: {
    backgroundColor: `${colours.blue}`,
    '&:hover': {
      backgroundColor: `${colours.blueDark}`,
    },
  },
  primaryButtonAllowaBlue: {
    backgroundColor: `${colours.allowaBlue}`,
    '&:hover': {
      backgroundColor: `${colours.allowaBlueDark}`,
    },
  },
  primaryButtonLightBlue: {
    borderRadius: 999,
    backgroundColor: `${colours.lightBlue}`,
    color: `${colours.allowaBlue}`,
    '&:hover': {
      backgroundColor: `${colours.lightBlueDark}`,
    },
  },
  thin: {
    fontSize: 14,
    fontWeight: 400,
    height: 33,
    padding: '0 20px',
  },
  fullWidth: {
    width: '100%',
  },
  fontSizeSmall: {
    fontSize: 12,
  },
  disabledButton: {
    background: '#9AA5B0 !important',
    color: 'white !important',
  },
  big: {
    height: 65,
  },
}))

function MyButton(props: customButton): React.ReactElement {
  const classes = useStyles()

  const handleOnClick = () => {
    if (!props.isDisabled && props.onClick) {
      props.onClick()
    }
  }

  return (
    <Button
      className={`${classes.primaryButton} ${props.orange && classes.primaryButtonOrange} ${
        props.green && classes.primaryButtonGreen
      } ${props.blue && classes.primaryButtonBlue} ${
        props.allowaBlue && classes.primaryButtonAllowaBlue
      } ${props.lightBlue && classes.primaryButtonLightBlue} ${props.thin && classes.thin} ${
        props.fullWidth && classes.fullWidth
      } ${props.fontSizeSmall && classes.fontSizeSmall} ${props.className} ${
        props.isDisabled && classes.disabledButton
      } ${props.big && classes.big}`}
      onClick={handleOnClick}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      disableElevation
      variant="contained"
      disabled={props.isDisabled}
    >
      {props.label}
    </Button>
  )
}

export default MyButton
