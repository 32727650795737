import React from 'react'
import { Link, navigate } from 'gatsby'
import styled from 'styled-components'

import { colours } from '../style/variables'
import dots from '../assets/dots-mobile.svg'
import home from '../assets/icons/home.svg'
import key from '../assets/icons/key.svg'
import person from '../assets/icons/person-menu.svg'
import chevronDown from '../assets/icons/chevron-down-white.svg'
import PrimaryButton from './common/Button'
import { useState } from 'react'

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background: ${colours.blue};
  z-index: 3;
`

const RightBackground = styled.div`
  position: absolute;
  top: 206px;
  right: 0px;
  background-image: url(${dots});
  width: 35px;
  height: 260px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px;
`

const Title = styled.p<{ spaceBetween?: boolean }>`
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  justify-content: ${props => (props.spaceBetween ? 'space-between' : 'initial')};
  align-items: center;
  color: #ffffff;
  cursor: pointer;
`

const SubTitle = styled(Link)`
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.5);
  margin-bottom: 16px;
  text-decoration: none;
`

const Icon = styled.img`
  margin: 0px;
  transition: 300ms all;
  transform: rotate(0deg);

  &.anchored {
    transition: 300ms all;
    transform: rotate(180deg);
  }
`

const Divider = styled.div`
  width: 275px;
  height: 1px;
  background: rgba(254, 254, 254, 0.5);
  margin-bottom: 20px;
`

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 34px;
  width: 140px;
  height: 105px;
  min-height: 105px;
  justify-content: space-between;
`

function MobileMenu() {
  const [open, setOpen] = useState(false)

  return (
    <Container>
      <RightBackground />
      <Content>
        <Title spaceBetween onClick={() => setOpen(!open)}>
          En savoir plus <Icon src={chevronDown} className={`${open ? 'anchored' : ''}`} />
        </Title>
        {open && (
          <div>
            <SubTitle to="/etapes-de-vente">Le guide vendeur</SubTitle>
            <SubTitle to="/baremes">Nos tarifs</SubTitle>
          </div>
        )}
        <Divider />
        <Title onClick={() => navigate('/estimation')}>Estimer mon bien</Title>
        <Divider />
        <Title>Nous contacter</Title>
        <ButtonsContainer>
          <PrimaryButton
            startIcon={<Icon src={home} />}
            orange
            onClick={() => navigate('https://www.allowa.com')}
            label="Acheter"
          />
          <PrimaryButton
            startIcon={<Icon src={key} />}
            allowaBlue
            onClick={() => navigate('https://www.allowa.com')}
            label="Louer"
          />
        </ButtonsContainer>
        <Divider />
        <Title>
          <Icon src={person} style={{ marginRight: 8 }} />
          Se connecter
        </Title>
        <Title>
          <Icon src={person} style={{ marginRight: 8 }} />
          S'inscrire
        </Title>
      </Content>
    </Container>
  )
}

export default MobileMenu
