import React from 'react'
import { navigate, Link } from 'gatsby'
import styled from 'styled-components'
import { device, colours } from '../../style/variables'

import routes from '../../utils/routes'
import MyButton from '../common/Button'

const Container = styled.div`
  font-size: 15px;
  font-weight: 500;
  line-height: 15px;

  &:last-child {
    margin-bottom: 2rem;
  }

  @media ${device.desktop} {
    margin: 0 1rem 0 3rem;
  }
`

const Column = styled.div`
  @media ${device.tablet} {
    margin-bottom: 2rem;
  }

  @media ${device.desktop} {
    &:first-of-type {
      margin-right: 6rem;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
`

const Title = styled.div`
  color: ${colours.grey};
  cursor: default;
  margin: 2rem 0;
`

const StyledLink = styled(Link)`
  color: white;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0;
  text-decoration: none;
  transition: 0.3s;

  &:hover {
    opacity: 0.6;
    transition: 0.3s;
  }

  @media ${device.tablet} {
    margin: 1.6rem 0 0 0;
  }
`

function FooterButtons(): React.ReactElement {
  return (
    <Container>
      <Column>
        <Title>Allowa</Title>
        <StyledLink to={routes.mission} target="_blank">Notre Mission</StyledLink>
        <StyledLink to={routes.equipe} target="_blank">Notre Équipe</StyledLink>
        <StyledLink to={routes.faq} target="_blank">Questions fréquentes</StyledLink>
      </Column>
      <MyButton allowaBlue label="Nous contacter" onClick={() => navigate('/contact')} />
    </Container>
  )
}

export default FooterButtons
