import React from 'react'
import styled from 'styled-components'
import { colours, device } from '../style/variables'

import FooterLogo from './footer/FooterLogo'
import MainLinks from './footer/MainLinks'
import AllowaLinks from './footer/AllowaLinks'
import SocialLinks from './footer/SocialLinks'
import LegalLinks from './footer/LegalLinks'

const Container = styled.div`
  background-color: ${colours.blue};
  padding: 1.5rem 2rem;

  @media ${device.tablet} {
    padding: 2rem 2rem 0 2rem;
  }

  @media ${device.desktop} {
    padding: 2rem 2rem 0 0;
  }
`

const InnerWrapper = styled.div`
  margin: 0 auto;
  max-width: 1140px;
`

const MainInfo = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Divider = styled.div`
  background-color: ${colours.allowaBlue};
  width: 100%;
  margin: 1rem auto;
  height: 1px;

  @media ${device.tablet} {
    background-color: #2d4b67;
  }

  @media ${device.desktop} {
    width: 93.5%;
    margin-left: 3rem;
  }
`

const SecondaryInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${device.tablet} {
    flex-direction: row;
    padding-bottom: 1rem;
  }

  @media ${device.desktop} {
    padding: 1rem 0;
  }
`

function Footer(): React.ReactElement {
  return (
    <Container>
      <InnerWrapper>
        <FooterLogo />
        <MainInfo>
          <MainLinks />
          <AllowaLinks />
        </MainInfo>
        <Divider />
        <SecondaryInfo>
          <SocialLinks />
          <Divider className="mobile-only" />
          <LegalLinks />
        </SecondaryInfo>
      </InnerWrapper>
    </Container>
  )
}

export default Footer
